import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import 'antd/dist/antd.css';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ToastProvider } from 'react-toast-notifications';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './component/ErrorBoundary';
import App from './App';

import store from './stores';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <CookiesProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <ErrorBoundary>
                        <ToastProvider>
                            <App />
                        </ToastProvider>
                    </ErrorBoundary>
                </MuiPickersUtilsProvider>
            </CookiesProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
