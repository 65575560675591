export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Admin Details
export const GET_ADMIN_DETAILS = `${API_BASE_URL}/public/auth/v1/get-admin-detail`;

// Audit Event Api's
export const GetAuditEventListApiURL = `${API_BASE_URL}/admin/audit/v1/get-event-list`;
export const CreateAuditEventApiURL = `${API_BASE_URL}/admin/audit/v1/create-event`;

// Auth and User Api's
export const RefreshTokenApiURL = `${API_BASE_URL}/public/admin/v1/refresh-token`;

// Auth and Login Api's
export const UserLoginApiURL = `${API_BASE_URL}/public/admin/v1/login`;

// Auth and Logout Api's
export const UserLogoutApiURL = `${API_BASE_URL}/admin/auth/v1/logout`;

// Auth and Forgot Password Api's
export const ForgotPasswordApiURL = `${API_BASE_URL}/public/admin/v1/forgot-password`;

// Auth and Confirm Forgot  Password Api's
export const ConfirmForgotPasswordApiURL = `${API_BASE_URL}/public/admin/v1/confirm-forgot-password`;

// Auth and Reset Password Api's
export const ResetPasswordApiURL = `${API_BASE_URL}/public/admin/v1/reset-password`;

// Get post Detail Api's
export const GetPostDetailApiURL = `${API_BASE_URL}/admin/community/post/v1/get-detail`;

// Get Post List Api's
export const GetPostListApiURL = `${API_BASE_URL}/admin/community/post/v1/get-list`;

// Create Post Api's
export const CreatePostApiURL = `${API_BASE_URL}/admin/community/post/v1/create`;

// Delete Post Api's
export const DeletePostApiURL = `${API_BASE_URL}/admin/community/post/v1/delete`;

// Update Post Api's
export const UpdatePostApiURL = `${API_BASE_URL}/admin/community/post/v1/update`;

// Get Post Filters Api's
export const GetPostFiltersApiURL = `${API_BASE_URL}/admin/community/post/v1/get-filters`;

// Get Author List Api's
export const GetAuthorListApiURL = `${API_BASE_URL}/admin/community/post/v1/get-author-list`;

// Get Question List Api's
export const GetQuestionListApiURL = `${API_BASE_URL}/admin/community/question/v1/get-list`;

// Create Question List Api's
export const CreateQuestionApiURL = `${API_BASE_URL}/admin/community/question/v1/create`;

// Delete Question Api's
export const DeleteQuestionApiURL = `${API_BASE_URL}/admin/community/question/v1/delete`;

// Update Question Api's
export const UpdateQuestionApiURL = `${API_BASE_URL}/admin/community/question/v1/update`;

// Get Question Detial
export const GetQuestionDetialApiURL = `${API_BASE_URL}/admin/community/question/v1/get-detail`;

// Get Question Filters Api's
export const GetQuestionFiltersApiURL = `${API_BASE_URL}/admin/community/question/v1/get-filters`;

// Get Poll List Api's
export const GetPollListApiURL = `${API_BASE_URL}/admin/community/poll/v1/get-list`;

// Get Poll Filters Api's
export const GetPollFiltersApiURL = `${API_BASE_URL}/admin/community/poll/v1/get-filters`;

// Delete Poll Api's
export const DeletePollApiURL = `${API_BASE_URL}/admin/community/poll/v1/delete`;

// Create Poll List Api's
export const CreatePollApiURL = `${API_BASE_URL}/admin/community/poll/v1/create`;

// Update Poll Api's
export const UpdatePollApiURL = `${API_BASE_URL}/admin/community/poll/v1/update`;

// Get Poll Detial
export const GetPollDetialApiURL = `${API_BASE_URL}/admin/community/poll/v1/get-detail`;

// Get Reaction Author List Api's
export const GetReactionAuthorListApiURL = `${API_BASE_URL}/admin/community/reaction/v1/get-list`;

// Get Blog List Api's
export const GetBlogListApiURL = `${API_BASE_URL}/admin/community/blog/v1/get-list`;

// Delete Blog Api's
export const DeleteBlogApiURL = `${API_BASE_URL}/admin/community/blog/v1/delete`;

// Get Blog Detail Api's
export const GetBlogDetailApiURL = `${API_BASE_URL}/admin/community/blog/v1/get-detail`;

// Update Blog Api's
export const UpdateBlogApiURL = `${API_BASE_URL}/admin/community/blog/v1/update`;

// Get User List Holding Api's
export const GetHoldingUserListApiURL = `${API_BASE_URL}/admin/investment/holding/v1/get-user-list`;

// Get User List Holding Detial
export const GetHoldingUserDetialApiURL = `${API_BASE_URL}/admin/investment/holding/v1/get-user-holding-detail`;

// Get Refresh user holdings
export const GetRefreshUserHoldingsApiURL = `${API_BASE_URL}/admin/investment/holding/v1/refresh-user-holding`;

// Get Comment List
export const GetCommentListApiURL = `${API_BASE_URL}/admin/community/comment/v1/get-list`;

// Delete Comment
export const DeleteCommentApiURL = `${API_BASE_URL}/admin/community/comment/v1/delete`;

// Get users list // App user
export const GetAppUsersListApiURL = `${API_BASE_URL}/admin/userentity/user/v1/get-list`;

// Update User Active Api's
export const ActiveUserApiURL = `${API_BASE_URL}/admin/userentity/user/v1/activate`;

// Deactive  User Active Api's
export const DeactiveUserApiURL = `${API_BASE_URL}/admin/userentity/user/v1/deactivate`;

// Get Calculator Master List
export const GetCalculatorListApiURL = `${API_BASE_URL}/admin/tool/calculator/v1/get-master-list`;

// Create Calculator Master List Api's
export const CreateCalculatorApiURL = `${API_BASE_URL}/admin/tool/calculator/v1/create`;

// Delete Calculator
export const DeleteCalculatorApiURL = `${API_BASE_URL}/admin/tool/calculator/v1/delete`;

// Update Calculator Api's
export const UpdateCalculatorApiURL = `${API_BASE_URL}/admin/tool/calculator/v1/update`;

// Get Model Portfolio List
export const GetModelPortfolioMasterListApiURL = `${API_BASE_URL}/admin/investment/model-portfolio/v1/get-list`;

// Delete Model Portfolio Api's
export const DeleteModelPortfolioApiURL = `${API_BASE_URL}/admin/investment/model-portfolio/v1/delete`;

// Create Model Portfolio List Api's
export const CreateModelPortfolioApiURL = `${API_BASE_URL}/admin/investment/model-portfolio/v1/create`;

// Update Model Portfolio Api's
export const UpdateModelPortfolioApiURL = `${API_BASE_URL}/admin/investment/model-portfolio/v1/update`;

// Get Report List
export const GetReportListApiURL = `${API_BASE_URL}/admin/community/report/v1/get-list`;

// Update Report Api's
export const UpdateReportApiURL = `${API_BASE_URL}/admin/community/report/v1/update`;

// Delete Report Api's
export const DeleteReportApiURL = `${API_BASE_URL}/admin/community/report/v1/delete-reported-content`;

// Get Deport Detail
export const GetReportDetialApiURL = `${API_BASE_URL}/admin/community/report/v1/get-detail`;

// Get Report Filters Api's
export const GetReportFiltersApiURL = `${API_BASE_URL}/admin/community/report/v1/get-filters`;

// Block User Report Api's
export const BlockUserApiURL = `${API_BASE_URL}/admin/community/user/v1/block`;

// API Analytics Dashboard
export const getApiAnalyticsDashboardEmbeddedURL = `${API_BASE_URL}/admin/analytics/api/v1/get-quicksight-embedded-url`;

// Get Event List Api's
export const GetEventListApiURL = `${API_BASE_URL}/admin/event/v1/get-list`;

// Get Event Detail Api's
export const GetEventDetailApiURL = `${API_BASE_URL}/admin/event/v1/get-detail`;

// Create Event Api's
export const CreateEventApiURL = `${API_BASE_URL}/admin/event/v1/create`;

// Delete Event Api's
export const DeleteEventApiURL = `${API_BASE_URL}/admin/event/v1/delete`;

// Update Event Api's
export const UpdateEventApiURL = `${API_BASE_URL}/admin/event/v1/update`;

// Get Banner List Api's
export const GetBannerListApiURL = `${API_BASE_URL}/admin/community/feed-card/v1/get-list`;

// Create Banner Api's
export const CreateBannerApiURL = `${API_BASE_URL}/admin/community/feed-card/v1/create`;

// Delete Banner Api's
export const DeleteBannerApiURL = `${API_BASE_URL}/admin/community/feed-card/v1/delete`;

// Update Banner Api's
export const UpdateBannerApiURL = `${API_BASE_URL}/admin/community/feed-card/v1/update`;

// API Create Comment Api's
export const CreateCommentApiURL = `${API_BASE_URL}/admin/community/comment/v1/create`;

// Get Application Version History List Api
export const GetApplicationVersionHistoryListApiURL = `${API_BASE_URL}/admin/application/application-version-history/v1/get-list`;

// Create Application Version History Api
export const CreateApplicationVersionHistoryApiURL = `${API_BASE_URL}/admin/application/application-version-history/v1/create`;

// Update Application Version History Api
export const UpdateApplicationVersionHistoryApiURL = `${API_BASE_URL}/admin/application/application-version-history/v1/update`;

// Delete Application Version History Api
export const DeleteApplicationVersionHistoryApiURL = `${API_BASE_URL}/admin/application/application-version-history/v1/delete`;

// Get App User Filters Api
export const GetAppUserFiltersApiURL = `${API_BASE_URL}/admin/userentity/user/v1/get-filters`;

// Get Tag List Api
export const GetTagListApiURL = `${API_BASE_URL}/admin/community/post/v1/get-tag-list`;

// Get Available Pinned Slot Api
export const GetAvailablePinnedSlotApiURL = `${API_BASE_URL}/admin/community/pinned/v1/get-available-slots`;

// Get Pinned Entity Details Api
export const GetPinnedEntityDetailsApiURL = `${API_BASE_URL}/admin/community/pinned/v1/get`;

// Add Pinned Entity Api
export const AddPinnedEntityApiURL = `${API_BASE_URL}/admin/community/pinned/v1/add`;

// Remove Pinned Entity Api
export const RemovePinnedEntityApiURL = `${API_BASE_URL}/admin/community/pinned/v1/remove`;

// Update Pinned Entity Api
export const UpdatePinnedEntityApiURL = `${API_BASE_URL}/admin/community/pinned/v1/update`;

// Get Mentions List Api
export const GetMentionsListApiURL = `${API_BASE_URL}/admin/userentity/user/v1/get-users`;

// Get Banner Registration List Api
export const GetBannerRegistrationListApiURL = `${API_BASE_URL}/admin/event/v1/get-registration-list`;

// Get Event Registration List Api
export const GetEventRegistrationListApiURL = `${API_BASE_URL}/admin/event/v1/get-registration-list`;

// Get Order List Api
export const GetOrderListApiURL = `${API_BASE_URL}/admin/investment/orders/v1/get-transaction-list`;

// Get Order List Filters
export const GetOrderFiltersApiURL = `${API_BASE_URL}/admin/investment/orders/v1/get-transaction-filters`;

// Delete Scheme Portfolio Mapping Api's
export const DeleteSchemePortfolioMappingApiURL = `${API_BASE_URL}/admin/investment/scheme-portfolio-mapping/v1/delete`;

// Create Scheme Portfolio Mapping Api's
export const CreateSchemePortfolioMappingApiURL = `${API_BASE_URL}/admin/investment/scheme-portfolio-mapping/v1/create`;

// Update Scheme Portfolio Mapping Api's
export const UpdateSchemePortfolioMappingApiURL = `${API_BASE_URL}/admin/investment/scheme-portfolio-mapping/v1/update`;
// Get User Mandate List
export const GetUserMandateListApiURL = `${API_BASE_URL}/admin/investment/user-mandate/v1/get-list`;

// Get User Mandate Filter
export const GetUserMandateFilterApiURL = `${API_BASE_URL}/admin/investment/user-mandate/v1/get-filters`;

// Delete User Mandate
export const DeleteUserMandateApiURL = `${API_BASE_URL}/admin/investment/user-mandate/v1/delete`;

// Update Transaction Order
export const UpdateOrderAPIURL = `${API_BASE_URL}/admin/investment/orders/v1/update`;

// Get Reward List Api's
export const GetRewardListApiURL = `${API_BASE_URL}/admin/community/wheel-reward/v1/get-list`;

// Create Reward Api's
export const CreateRewardApiURL = `${API_BASE_URL}/admin/community/wheel-reward/v1/create`;

// Update Reward Api's
export const UpdateRewardApiURL = `${API_BASE_URL}/admin/community/wheel-reward/v1/update`;

// Delete Reward Api's
export const DeleteRewardURL = `${API_BASE_URL}/admin/community/wheel-reward/v1/delete`;

export const CreateGeneralUpdateURL = `${API_BASE_URL}/admin/community/v3/updates/general`;

export const DeleteGenralUpdateURL = `${API_BASE_URL}/admin/community/v3/updates/general`;

export const UpdateGeneralUpdateURL = `${API_BASE_URL}/admin/community/v3/updates/general`;

export const ViewGeneralUpdateURL = `${API_BASE_URL}/admin/community/v3/updates/general`;

export const CreateSipUpdateURl = `${API_BASE_URL}/admin/community/v3/updates/sip`;

export const BulkCreateSipUpdateURL = `${API_BASE_URL}/admin/community/v3/updates/sip/bulk`;

export const UpdateSipURL = `${API_BASE_URL}/admin/community/v3/updates/sip`;

export const ViewSipURL = `${API_BASE_URL}/admin/community/v3/updates/sip`;

export const DeleteSipURL = `${API_BASE_URL}/admin/community/v3/updates/sip`;

export const GetSipClientCodeURL = `${API_BASE_URL}/admin/community/v3/updates/sip/clientcodes`;

export const GetReferralURL = `${API_BASE_URL}/admin/community/v3/rewards/history`;

export const GetReferralMobileNumberListURL = `${API_BASE_URL}/admin/community/v3/rewards/history/mobile`;

export const GetReferralRewardNameListURL = `${API_BASE_URL}/admin/community/v3/rewards/history/reward-name`;

export const GetReferralRefreeIdListURL = `${API_BASE_URL}/admin/community/v3/rewards/history/referree`;

// Get FD Api's
export const GetFd = `${API_BASE_URL}/admin/community/v3/updates/fd`;

export const UpdateFd = `${API_BASE_URL}/admin/community/v3/updates/fd/bulk`;

export const generateRandomeUserUpdate = `${API_BASE_URL}/admin/community/v3/updates/general/bulk`;
export const uploadImagesToS3 = `${API_BASE_URL}/admin/uploadimage/v1/upload`;
