/* eslint-disable */
import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import swal from "sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CommonTable from "../Common/Table";
import {
  GetModelPortfolioMasterListApi,
  DeleteModelPortfolioApi,
  CreateModelPortfolioApi,
  UpdateModelPortfolioApi,
} from "../../api/modelPortfolio";
import { GetUploadS3ImageApi } from "../../api/uploadImageS3";
import CommonLoader from "../Common/Loader";
import Paginator from "../Common/Paginator";
import CommonDropdown from "../Common/Dropdown";
import CommonDatePicker from "../Common/DatePicker";
import { Modal, Button, Select, Input } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import isEmpty from "lodash/isEmpty";
import parseInt from "lodash/parseInt";
import { size, trim } from "lodash";
import {
  StyledButton,
  FilterGrid,
  FilterTitleWrapper,
  IconContainer,
  StyleModal,
  LabelInput,
  SubLabelInput,
  ErrorText,
  SchemePortfolio,
  StyledTag,
  StyledInput,
} from "./styles";
import { buID } from "../../constant";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { NavLink } from "react-router-dom";
import {
  snakeToUpperCase,
  isValidImageUploadObject,
  clearSession,
} from "../../util/helper";
import Image from "../Image";
import { getImageWithBucketCreds, s3Config } from "../../util/custom";
import { RNS3 } from "react-native-aws3";

const ModelPortfolios = () => {
  const [name, setName] = useState([]);
  const [description, setDescription] = useState("");
  const [targetReturn, setTargetReturn] = useState("");
  const [estimatedReturn, setEstimatedReturn] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const [minLumpsum, setMinLumpsum] = useState("");
  const [maxLumpsum, setMaxLumpsum] = useState("");
  const [minSIP, setMinSIP] = useState("");
  const [maxSIP, setMaxSIP] = useState("");
  const [sipDates, setSipDates] = useState("");
  const [recommendedPeriod, setRecommendedPeriod] = useState("");
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [minRedemption, setMinRedemption] = useState("");
  const [pitchingFacts, setPitchingFacts] = useState([]);

  const [updateErrors, setUpdateErrors] = useState({});
  const [currentUpdatePortfolio, setCurrentUpdatePortfolio] = useState({});
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  const [systemPortfolioID, setSystemPortfolioID] = useState();
  const [fd, setFd] = useState();
  const [lxme, setLxme] = useState();
  const [savingsAC, setSavingsAC] = useState();
  const [imageFile, setImageFile] = useState("");
  const [pichingFactsArray, setPichingFactsArray] = useState([""]);
  const [isUpdatedImage, setIsUpdatedImage] = useState(false);

  const [filters, setFilters] = useState({
    page: currentPage,
    page_limit: pageSize,
    sort_by: "updated_at",
    sort_type: "desc",
    is_deleted: "false",
  });

  const IsDeleted = {
    key: "is_deleted",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };
  const sortByList = {
    key: "sort_by",
    data: [
      {
        id: "id",
        title: "id",
        selected: false,
      },
      {
        id: "created_at",
        title: "created_at",
        selected: false,
      },
      {
        id: "updated_at",
        title: "updated_at",
        selected: true,
      },
    ],
  };

  const renderProductComparisions = (product = [], text = {}) => {
    const productComparirionsArray = [];
    product.map((element) => {
      productComparirionsArray.push(
        <p>
          {element.title}: {element.return_percentage}
        </p>
      );
      return 0;
    });

    return productComparirionsArray;
  };

  const renderSIPDates = (dates = []) => {
    const tagArray = [];
    if (dates != null) {
      dates.map((date) => {
        tagArray.push(<StyledTag key={date}>{date}</StyledTag>);
        return 0;
      });
    }
    return tagArray;
  };

  const createOptionList = (list) => {
    const optionArray = [];
    // eslint-disable-next-line array-callback-return
    list.map((item) => {
      optionArray.push(
        <Option key={item.id} value={item.id}>
          {item.title}
        </Option>
      );
    });

    return optionArray;
  };

  const tableColumns = [
    {
      title: "Portfolio Name",
      dataIndex: "name",
      key: "name",
      width: 10,
      align: "center",
    },
    {
      title: "System Portfolio ID",
      dataIndex: "system_portfolio_id",
      key: "system_portfolio_id",
      width: 10,
      align: "center",
      render: (data) => data,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 30,
      align: "center",
      render: (data) => data,
    },
    {
      title: "CAGR",
      dataIndex: "target_return_percentage",
      key: "target_return_percentage",
      width: 10,
      align: "center",
    },
    {
      title: "Estimated return",
      dataIndex: "estimated_return_percentage",
      key: "estimated_return_percentage",
      width: 10,
      align: "center",
    },

    {
      title: "Recommended For",
      dataIndex: "recommended_period",
      key: "recommended_period",
      width: 10,
      align: "center",
    },
    {
      title: "Min Redemption Amount",
      dataIndex: "min_redemption_amount",
      key: "min_redemption_amount",
      width: 10,
      align: "center",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: 10,
      align: "center",
    },
    {
      title: "Product Comparisions",
      dataIndex: "product_comparisions",
      key: "product_comparisions",
      width: 10,
      align: "center",
      render: (product, text) =>
        renderProductComparisions((product || {}).products || [], text),
    },
    {
      title: "Min lumpsum",
      dataIndex: "min_lumpsum_amount",
      key: "min_lumpsum_amount",
      width: 10,
      align: "center",
      render: (data) => data.toLocaleString("en"),
    },
    {
      title: "Max lumpsum",
      dataIndex: "max_lumpsum_amount",
      key: "max_lumpsum_amount",
      width: 10,
      align: "center",
      render: (data) => data.toLocaleString("en"),
    },
    {
      title: "Min SIP",
      dataIndex: "min_sip_amount",
      key: "min_sip_amount",
      width: 10,
      align: "center",
      render: (data) => data.toLocaleString("en"),
    },

    {
      title: "Max SIP",
      dataIndex: "max_sip_amount",
      key: "max_sip_amount",
      width: 10,
      align: "center",
      render: (data) => data.toLocaleString("en"),
    },
    {
      title: "SIP Dates",
      dataIndex: "sip_dates",
      key: "sip_dates",
      width: 10,
      align: "center",
      render: (item, text) => renderSIPDates(item, text),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 10,
      align: "center",
      render: (item) => <p>{snakeToUpperCase(item)}</p>,
    },
  ];

  if (!JSON.parse(filters.is_deleted || false)) {
    tableColumns.push({
      title: "Edit",
      dataIndex: "id",
      key: "id",
      width: 10,
      render: (text, item) => (
        <IconContainer>
          <EditIcon onClick={() => showUpdatePortfoliosModal(item)} />
        </IconContainer>
      ),
      align: "center",
    });
    tableColumns.push({
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 10,
      render: (text, item) =>
        item.is_deleted === false ? (
          <IconContainer>
            <DeleteIcon onClick={() => deleteModelPortfolio(text)} />
          </IconContainer>
        ) : null,
    });
  }

  const expandable = {
    expandedRowRender: (record) => {
      const portfolioID = record.id;
      const systemPortfolioID = record.system_portfolio_id;
      const schemes = record.scheme_details;

      for (let element in schemes) {
        schemes[element];
      }
      const columns = [
        {
          title: "Scheme Name",
          dataIndex: "name",
          key: "1",
          align: "center",
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "2",
          align: "center",
        },
        {
          title: "Last 3Y Return",
          dataIndex: "three_year_return",
          key: "3",
          align: "center",
          render: (item) => <p>{item}</p>,
        },
        {
          title: "Last 5Y Return",
          dataIndex: "five_year_return",
          key: "4",
          align: "center",
          render: (item) => <p>{item}</p>,
        },
        {
          title: "Since Inception Retrun",
          dataIndex: "inception_return",
          key: "5",
          align: "center",
          render: (item) => <p>{item}</p>,
        },

        {
          title: "Allocation Percentage",
          dataIndex: "allocation_percentage",
          key: "6",
          align: "center",
          render: (item) => <p>{item}</p>,
        },

        {
          title: "Scheme Type",
          dataIndex: "asset_class",
          key: "7",
          align: "center",
          render: (item) => <p>{item}</p>,
        },
      ];

      return (
        <SchemePortfolio>
          <NavLink to={`/investment/model-portfolio/${portfolioID}`}>
            <StyledButton backgroundColor={"#000000"}>
              {(schemes || []).length > 0 ? "Edit Schemes" : "Add Schemes"}
            </StyledButton>
          </NavLink>

          <CommonTable
            columns={columns}
            data={schemes}
            pagination={false}
            rowKey="id"
          />
        </SchemePortfolio>
      );
    },
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
      ) : (
        <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
      ),
  };

  const sortTypeList = {
    key: "sort_type",
    data: [
      {
        id: "asc",
        title: "Asc",
        selected: false,
      },
      {
        id: "desc",
        title: "Desc",
        selected: true,
      },
    ],
  };

  const getModelPortfolioList = (params) => {
    setLoading(true);
    GetModelPortfolioMasterListApi(params)
      .then((res) => {
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};

            setData(apiData.results);
            setCurrentPage(apiData.page);
            setPageSize(apiData.page_limit);
            setTotal(apiData.total_count);
            setFilters(params);
            setLoading(false);
          }
        }
        if (!success) {
          setLoading(false);
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  useEffect(() => {
    filters.page = currentPage;
    filters.page_limit = pageSize;
    getModelPortfolioList(filters);
  }, [currentPage, pageSize]);

  const handleFilterChange = (filters) => {
    getModelPortfolioList(filters);
  };

  const deleteModelPortfolio = (id) => {
    swal({
      title: "Are you sure you want to delete this portfolio ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        setLoading(true);
        const params = {
          id,
        };
        DeleteModelPortfolioApi(params)
          .then((res) => {
            let success = false;
            setLoading(false);
            if (res.status === 200) {
              if ((res.data || {}).success) {
                success = true;
                getModelPortfolioList(filters);
                swal("success!", "Successfully Deleted Portfolio", "success");
              }
            }
            if (!success) {
              setLoading(false);
              swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
            }
          })
          .catch((error) => {
            setLoading(false);
            let errorMessage = "Failed to delete portfolio";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
          });
      }
    });
  };

  const validateFields = (values) => {
    const validationErrors = {};
    // Portfolio Name
    if (size(trim(values.name)) === 0) {
      validationErrors.name = "Required Portfolio Name";
    }

    if (size(trim(values.description)) === 0) {
      validationErrors.description = "Required Description";
    }

    if (isEmpty(values.targetReturn) || parseInt(values.targetReturn) < 1) {
      validationErrors.targetReturn = "Invalid CAGR";
    }

    if (
      isEmpty(values.estimatedReturn) ||
      parseInt(values.estimatedReturn) < 1
    ) {
      validationErrors.estimatedReturn = "Invalid Estimated Return";
    }

    if (isEmpty(values.timePeriod) || parseInt(values.timePeriod) < 1) {
      validationErrors.timePeriod = "Invalid timePeriod";
    }

    if (isEmpty(values.minLumpsum) || parseInt(values.minLumpsum) < 0) {
      validationErrors.minLumpsum = "Invalid minLumpsum";
    }

    if (isEmpty(values.maxLumpsum) || parseInt(values.maxLumpsum) < 0) {
      validationErrors.maxLumpsum = "Invalid maxLumpsum";
    } else if (parseInt(values.maxLumpsum) < parseInt(values.minLumpsum)) {
      validationErrors.maxLumpsum =
        "Max lumpsum should be greater than min lumpsum";
    }

    if (isEmpty(values.minSIP) || parseInt(values.minSIP) < 0) {
      validationErrors.minSIP = "Invalid manimum SIP Amount";
    }

    if (isEmpty(values.maxSIP) || parseInt(values.maxSIP) < 0) {
      validationErrors.maxSIP = "Invalid maximum SIP Amount";
    } else if (parseInt(values.maxSIP) < parseInt(values.minSIP)) {
      validationErrors.maxSIP = "Max SIP should be greater than min SIP";
    }

    if (isEmpty(values.sipDates)) {
      validationErrors.sipDates = "Invalid SIP Dates";
    }

    if (
      isEmpty(values.systemPortfolioID) ||
      parseInt(values.systemPortfolioID) < 0
    ) {
      validationErrors.systemPortfolioID = "Invalid System Portfolio ID";
    }

    if (isEmpty(values.recommendedPeriod)) {
      validationErrors.recommendedPeriod = "Invalid Recommended Period";
    }

    if (isEmpty(values.minRedemption) || parseInt(values.minRedemption) < 0) {
      validationErrors.minRedemptionAmount =
        "Invalid Minimum Redemption Amount";
    }

    if (isEmpty(values.position) || parseInt(values.position) < 0) {
      validationErrors.position = "Invalid Position";
    }

    if (isEmpty(values.fd) || parseInt(values.fd) < 0) {
      validationErrors.fd = "Invalid FD";
    }

    if (isEmpty(values.lxme) || parseInt(values.lxme) < 0) {
      validationErrors.lxme = "Invalid Lxme";
    }

    if (isEmpty(values.savingsAC) || parseInt(values.savingsAC) < 0) {
      validationErrors.savingsAC = "Invalid savings A/C";
    }
    return validationErrors;
  };

  const showCreatePortfoliosModal = () => {
    setShowCreateModal(true);
  };

  const handleAddModelPortfoliosModalCancel = () => {
    setName("");
    setDescription("");
    setTargetReturn("");
    setEstimatedReturn("");
    setTimePeriod("");
    setMaxLumpsum("");
    setMinLumpsum("");
    setMaxSIP("");
    setMinSIP("");
    setPosition("");
    setMinRedemption("");
    setSipDates([]);
    setSystemPortfolioID("");
    setRecommendedPeriod("");
    setFd("");
    setLxme("");
    setSavingsAC("");
    setPichingFactsArray([""]);
    setImageFile("");
    setShowCreateModal(false);
    setStatus("");
    setErrors({});
  };

  const showUpdatePortfoliosModal = (obj) => {
    setCurrentUpdatePortfolio(obj);
    setName(obj.name);
    setDescription(obj.description);
    setTargetReturn(obj.target_return_percentage.toString());
    setEstimatedReturn(obj.estimated_return_percentage?.toString() || "");
    setTimePeriod(obj.lower_term_period.toString());
    setMaxLumpsum(obj.max_lumpsum_amount.toString());
    setMinLumpsum(obj.min_lumpsum_amount.toString());
    setMaxSIP(obj.max_sip_amount.toString());
    setMinSIP(obj.min_sip_amount.toString());
    setSipDates(obj.sip_dates);
    setSystemPortfolioID(obj.system_portfolio_id.toString());
    setRecommendedPeriod(obj.recommended_period);
    setMinRedemption(obj.min_redemption_amount.toString());
    setPosition(obj.position.toString());
    setFd(
      ((obj.product_comparisions || {}).products ||
        [])[0].return_percentage.toString()
    );
    setLxme(
      ((obj.product_comparisions || {}).products ||
        [])[1].return_percentage.toString()
    );
    setSavingsAC(
      ((obj.product_comparisions || {}).products ||
        [])[2].return_percentage.toString()
    );
    setPichingFactsArray(obj.pitching_facts);
    setImageFile(obj.banner_image);
    setStatus(obj.status);
    setShowUpdateModal(true);
    setErrors({});
  };

  const handleAddModelPortfoliosSubmit = (event) => {
    event.preventDefault();
    const values = {
      name,
      description,
      targetReturn,
      estimatedReturn,
      timePeriod,
      minLumpsum,
      minSIP,
      maxLumpsum,
      maxSIP,
      position,
      minRedemption,
      sipDates,
      systemPortfolioID,
      recommendedPeriod,
      minRedemption,
      fd,
      lxme,
      savingsAC,
    };
    const uploadfile = imageFile;
    setErrors({});
    let validationErrors = {};
    validationErrors = validateFields(values);
    setErrors(validationErrors);

    if (isEmpty(validationErrors)) {
      if (isValidImageUploadObject(uploadfile)) {
        const createTimeNow = new Date();
        let keyPrefix = `${
          (currentUpdatePortfolio || {}).id
        }/portfolio/photo/${createTimeNow.toISOString()}`;

        keyPrefix += "-"+ uploadfile.name;
        let reader = new FileReader();
        reader.readAsDataURL(uploadfile);
        reader.onload = function () {
          const base64img = reader.result.split(',')[1];
          const params = {  
            filename: keyPrefix,
            encodedimg: base64img,
          }
          GetUploadS3ImageApi(params)
            .then((res) => {
              if (res.status === 200) {
                if (res.data) {
                  console.log("success in file upload", res.data);
                  const imgS3Param = {
                    location: res.data.data.url,
                    key: keyPrefix,
                  }
                  const image = getImageWithBucketCreds({
                    data: imgS3Param,
                  });
                  createPortfolios(image);
                }
              }
              else {
                console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
              }
            })
            .catch((error) => {
              let errorMessage = "error in file upload";
              if (error.response) {
                if (error.response.status === 400) {
                  errorMessage = (error.response.data || {}).error_desc;
                }
              }
              console.log("error in file upload", errorMessage);
            });
        };
        reader.onerror = function (error) {
          console.log("Error in file reading: ", error);
        };
        // keyPrefix += "-";
        // const config = { ...s3Config, keyPrefix };
        // if (process.env.REACT_APP_AWS_ACL) {
        //   config.acl = process.env.REACT_APP_AWS_ACL;
        // }
        // RNS3.put(uploadfile, config)
        //   .progress((progress) => console.log("progress", progress))
        //   .then((response) => {
        //     if (response.status !== 201) {
        //       console.log("error in file upload", response);
        //     } else {
        //       const image = getImageWithBucketCreds({
        //         data: (response.body || {}).postResponse,
        //       });
        //       console.log("file upload success");
        //       createPortfolios(image);
        //     }
        //   });
      } else {
        createPortfolios();
      }
    }
  };

  // create from
  const createPortfolios = (media) => {
    const params = {
      name: trim(name),
      banner_image: media
        ? {
            bucket: media.bucket,
            key: media.photo[0].key,
            pre_signed_url: media.photo[0].presigned_url,
            url: media.photo[0].url,
          }
        : {},
      description: trim(description),
      buid: buID,
      target_return_percentage: trim(targetReturn),
      estimated_return_percentage: trim(estimatedReturn),
      min_sip_amount: trim(minSIP),
      max_sip_amount: trim(maxSIP),
      min_lumpsum_amount: trim(minLumpsum),
      max_lumpsum_amount: trim(maxLumpsum),
      lower_term_period: trim(timePeriod),
      upper_term_period: trim(timePeriod),
      position: trim(position),
      min_redemption_amount: trim(minRedemption),
      status: "draft",
      system_portfolio_id: systemPortfolioID,
      sip_dates: sipDates,
      recommended_period: trim(recommendedPeriod),
      pitching_facts: pichingFactsArray,
      product_comparisions: {
        products: [
          {
            id: "fd",
            title: "FD",
            return_percentage: fd,
          },
          {
            id: "lxme",
            title: "LXME",
            return_percentage: lxme,
          },
          {
            id: "savings_ac",
            title: "Savings A/C",
            return_percentage: savingsAC,
          },
        ],
      },
    };

    setLoading(true);
    CreateModelPortfolioApi(params)
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleAddModelPortfoliosModalCancel();
            getModelPortfolioList(filters);
            swal("success!", "Successfully Create Portfolio", "success");
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Portfolio Creation Failed";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("OOPS!", errorMessage, "error");
      });
  };

  // update from
  const updatePortfolio = (media) => {
    const params = {
      id: (currentUpdatePortfolio || {}).id,
      buid: buID,
      name: trim(name),
      banner_image: media
        ? {
            bucket: media.bucket,
            key: media.photo[0].key,
            pre_signed_url: media.photo[0].presigned_url,
            url: media.photo[0].url,
          }
        : {},
      description: trim(description),
      target_return_percentage: trim(targetReturn),
      estimated_return_percentage: trim(estimatedReturn),
      min_sip_amount: trim(minSIP),
      max_sip_amount: trim(maxSIP),
      min_lumpsum_amount: trim(minLumpsum),
      max_lumpsum_amount: trim(maxLumpsum),
      lower_term_period: trim(timePeriod),
      upper_term_period: trim(timePeriod),
      status: status,
      scheme_details: [],
      position: trim(position),
      min_redemption_amount: trim(minRedemption),
      system_portfolio_id: systemPortfolioID,
      sip_dates: sipDates.map(String),
      recommended_period: trim(recommendedPeriod),
      pitching_facts: pichingFactsArray,
      product_comparisions: {
        products: [
          {
            id: "fd",
            title: "FD",
            return_percentage: fd,
          },
          {
            id: "lxme",
            title: "LXME",
            return_percentage: lxme,
          },
          {
            id: "savings_ac",
            title: "Savings A/C",
            return_percentage: savingsAC,
          },
        ],
      },
    };
    setLoading(true);
    UpdateModelPortfolioApi(params)
      .then((res) => {
        setLoading(false);
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleUpdateModalCancel();
            getModelPortfolioList(filters);
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to Update Portfolio";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const handleUpdateModalCancel = () => {
    setCurrentUpdatePortfolio("");
    setMinLumpsum("");
    setMaxLumpsum("");
    setMinSIP("");
    setMaxSIP("");
    setName("");
    setTargetReturn("");
    setEstimatedReturn("");
    setTimePeriod("");
    setShowUpdateModal(false);
    setPosition("");
    setMinRedemption("");
    setSipDates([]);
    setSystemPortfolioID("");
    setRecommendedPeriod("");
    setFd("");
    setLxme("");
    setSavingsAC("");
    setStatus("");
    setPichingFactsArray([""]);
    setImageFile("");
    setErrors({});
    setUpdateErrors({});
    setShowCreateModal(false);
  };

  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    const values = {
      name,
      description,
      targetReturn,
      estimatedReturn,
      timePeriod,
      minLumpsum,
      minSIP,
      maxLumpsum,
      maxSIP,
      position,
      minRedemption,
      sipDates,
      systemPortfolioID,
      recommendedPeriod,
      minRedemption,
      fd,
      lxme,
      savingsAC,
    };
    setUpdateErrors({});

    const validationErrors = validateFields(values);
    setErrors(validationErrors);

    const uploadfile = imageFile;

    if (isEmpty(validationErrors)) {
      if (isUpdatedImage && isValidImageUploadObject(uploadfile)) {
        const createTimeNow = new Date();
        let keyPrefix = `${
          (currentUpdatePortfolio || {}).id
        }/portfolio/photo/${createTimeNow.toISOString()}`;
        keyPrefix += "-"+ uploadfile.name;
        let reader = new FileReader();
        reader.readAsDataURL(uploadfile);
        reader.onload = function () {
          const base64img = reader.result.split(',')[1];
          const params = {  
            filename: keyPrefix,
            encodedimg: base64img,
          }
          GetUploadS3ImageApi(params)
            .then((res) => {
              if (res.status === 200) {
                if (res.data) {
                  console.log("success in file upload", res.data);
                  const imgS3Param = {
                    location: res.data.data.url,
                    key: keyPrefix,
                  }
                  const image = getImageWithBucketCreds({
                    data: imgS3Param,
                  });
                  updatePortfolio(image);
                }
              }
              else {
                console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
              }
            })
            .catch((error) => {
              let errorMessage = "error in file upload";
              if (error.response) {
                if (error.response.status === 400) {
                  errorMessage = (error.response.data || {}).error_desc;
                }
              }
              console.log("error in file upload", errorMessage);
            });
        };
        reader.onerror = function (error) {
          console.log("Error in file reading: ", error);
        };

        // keyPrefix += "-";
        // const config = { ...s3Config, keyPrefix };
        // if (process.env.REACT_APP_AWS_ACL) {
        //   config.acl = process.env.REACT_APP_AWS_ACL;
        // }
        // RNS3.put(uploadfile, config)
        //   .progress((progress) => console.log("progress", progress))
        //   .then((response) => {
        //     if (response.status !== 201) {
        //       console.log("error in file upload", response);
        //     } else {
        //       const image = getImageWithBucketCreds({
        //         data: (response.body || {}).postResponse,
        //       });
        //       updatePortfolio(image);
        //     }
        //   });
      } else {
        updatePortfolio({
          bucket: imageFile?.bucket,
          photo: [
            {
              key: imageFile?.key,
              presigned_url: imageFile?.pre_signed_url,
              url: imageFile?.url,
            },
          ],
        });
      }
    }
    setIsUpdatedImage(false);
  };

  const recommendedPeriodList = {
    key: "recommended_period",
    data: [
      {
        id: "Recommended for 1-11 months",
        title: "Recommended for 1-11 months",
      },
      {
        id: "Recommended for 1-3 years",
        title: "Recommended for 1-3 years",
      },
      {
        id: "Recommended for more than 3 years",
        title: "Recommended for more than 3 years",
      },
    ],
  };

  const statusList = {
    key: "status",
    data: [
      {
        id: "draft",
        title: "Draft",
      },
      {
        id: "active",
        title: "Active",
      },
    ],
  };

  const sipDatesList = () => {
    const data = [];
    for (let index = 1; index <= 31; index++) {
      data.push({
        id: index.toString(),
        title: index.toString(),
      });
    }
    return data;
  };

  const onFileChange = (event) => {
    if (!event) {
      setImageFile("");
      return;
    }
    const file = event.target?.files[0];
    setImageFile(file);
    setIsUpdatedImage(true);
  };

  const updatePichingFactsArray = (action) => {
    if (action === "Add") {
      setPichingFactsArray([...pichingFactsArray, ""]);
    } else {
      let pichingFacts = pichingFactsArray;
      pichingFacts.pop();
      setPichingFactsArray(pichingFacts);
    }
  };

  const setPichingFacts = (value, index) => {
    let pichingFacts = pichingFactsArray;
    pichingFacts[index] = value;
    setPichingFactsArray(pichingFacts);
  };

  const renderPichingFacts = () => {
    const PichingFactsRenderArray = [];
    pichingFactsArray.map((ele, index) => {
      PichingFactsRenderArray.push(
        <StyledInput
          id={"pitchingFacts" + `${index}`}
          placeholder="Pitching Facts"
          name={"pitchingFacts" + `${index}`}
          onChange={(e) => setPichingFacts(e.target.value, index)}
        />
      );
      return 0;
    });

    return (
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <LabelInput>Pitching Facts</LabelInput>
          <Grid>{PichingFactsRenderArray}</Grid>
          <Button
            key="Add"
            type="primary"
            onClick={(e) => updatePichingFactsArray("Add")}
            icon={<PlusCircleOutlined />}
          >
            Add
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2}>
      <CommonLoader
        isLoading={loading}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <StyledButton primary onClick={() => showCreatePortfoliosModal()}>
          Add Portfolio
        </StyledButton>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
          <div>
            <FilterTitleWrapper>Filters</FilterTitleWrapper>
            {/* <CommonDropdown
                            list={StatusList}
                            title="Status"
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        /> */}
            <CommonDropdown
              list={IsDeleted}
              title="Is Deleted"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDropdown
              list={sortByList}
              title="Sort By"
              searchable={false}
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDropdown
              list={sortTypeList}
              title="Sort Type"
              searchable={false}
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDatePicker
              title="From Date"
              type="inline"
              dateFormat="dd/MM/yyyy"
              filterKey="created_at_lte"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDatePicker
              title="To Date"
              type="inline"
              dateFormat="dd/MM/yyyy"
              filterKey="created_at_gte"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDatePicker
              title="Updated At LTE"
              type="inline"
              dateFormat="dd/MM/yyyy"
              filterKey="updated_at_lte"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDatePicker
              title="Updated At GTE"
              type="inline"
              dateFormat="dd/MM/yyyy"
              filterKey="updated_at_gte"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
          </div>
        </FilterGrid>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          <CommonTable
            data={data}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: "overflow" }}
            rowKey={"id"}
            expandable={expandable}
          />
          <Paginator
            totalRecords={total}
            pageLimit={pageSize}
            pageNeighbours={2}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
      <Grid>
        {showCreateModal && (
          <StyleModal
            title="Create Portfolio"
            visible={showCreateModal}
            onOk={(e) => handleAddModelPortfoliosSubmit(e)}
            onCancel={() => handleAddModelPortfoliosModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleAddModelPortfoliosSubmit(e)}
              >
                Add Portfolios
              </Button>,
            ]}
          >
            <LabelInput>Portfolio Name</LabelInput>
            <Input
              id="Name"
              placeholder="Name"
              name="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <ErrorText>{errors.name}</ErrorText>}

            <LabelInput>Description</LabelInput>
            <Input
              id="Description"
              placeholder="Description"
              name="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {errors.description && <ErrorText>{errors.description}</ErrorText>}
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>CAGR</LabelInput>
                <Input
                  type="number"
                  id="targetReturn"
                  placeholder="CAGR"
                  name="targetReturn"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={targetReturn}
                  onChange={(e) => setTargetReturn(e.target.value)}
                />
                {errors.targetReturn && (
                  <ErrorText>{errors.targetReturn}</ErrorText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <LabelInput>Time period</LabelInput>
                <Input
                  type="number"
                  id="timePeriod"
                  placeholder="Time period"
                  name="timePeriod"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(e.target.value)}
                />
                {errors.timePeriod && (
                  <ErrorText>{errors.timePeriod}</ErrorText>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Minimum lumpsum Amount</LabelInput>
                <Input
                  type="number"
                  id="minLumpsum"
                  placeholder="Minimum lumpsum Amount"
                  name="minLumpsum"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={minLumpsum}
                  onChange={(e) => setMinLumpsum(e.target.value)}
                />
                {errors.minLumpsum && (
                  <ErrorText>{errors.minLumpsum}</ErrorText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <LabelInput>Maximum lumpsum Amount</LabelInput>
                <Input
                  type="number"
                  id="maxLumpsum"
                  placeholder="Maximum lumpsum Amount"
                  name="maxLumpsum"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={maxLumpsum}
                  onChange={(e) => setMaxLumpsum(e.target.value)}
                />
                {errors.maxLumpsum && (
                  <ErrorText>{errors.maxLumpsum}</ErrorText>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Minimum SIP Amount</LabelInput>
                <Input
                  type="number"
                  id="minSIP"
                  placeholder="Minimum SIP"
                  name="minSIP"
                  maxLength={15}
                  value={minSIP}
                  onChange={(e) => setMinSIP(e.target.value)}
                />
                {errors.minSIP && <ErrorText>{errors.minSIP}</ErrorText>}
              </Grid>

              <Grid item xs={12} md={6}>
                <LabelInput>Maximum SIP Amount</LabelInput>
                <Input
                  type="number"
                  id="maxSIP"
                  placeholder="Maximum SIP Amount"
                  name="maxSIP"
                  maxLength={15}
                  value={maxSIP}
                  onChange={(e) => setMaxSIP(e.target.value)}
                />
                {errors.maxSIP && <ErrorText>{errors.maxSIP}</ErrorText>}
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>SIP Dates</LabelInput>
                <Select
                  mode="multiple"
                  placeholder="SIP Dates"
                  style={{ width: "100%" }}
                  defaultValue={sipDates}
                  onChange={(e) => setSipDates(e)}
                >
                  {createOptionList(sipDatesList())}
                </Select>
                {errors.sipDates && <ErrorText>{errors.sipDates}</ErrorText>}
              </Grid>

              <Grid item xs={12} md={6}>
                <LabelInput>System Portfolio ID 1</LabelInput>
                <Input
                  type="number"
                  id="systemPortfolioID"
                  placeholder="System Portfolio ID"
                  name="systemPortfolioID"
                  value={systemPortfolioID}
                  onChange={(e) => setSystemPortfolioID(e.target.value)}
                />
                {errors.systemPortfolioID && (
                  <ErrorText>{errors.systemPortfolioID}</ErrorText>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Recommended Period</LabelInput>
                <Select
                  placeholder="Recommended Period"
                  style={{ width: "100%" }}
                  value={recommendedPeriod}
                  onChange={(e) => setRecommendedPeriod(e)}
                >
                  {recommendedPeriodList &&
                    createOptionList(recommendedPeriodList.data)}
                </Select>
                {errors.recommendedPeriod && (
                  <ErrorText>{errors.recommendedPeriod}</ErrorText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <LabelInput>Minimum Redemption Amount</LabelInput>
                <Input
                  type="number"
                  id="min_redemption_amount"
                  placeholder="Minimum Redemption Amount"
                  name="min_redemption_amount"
                  value={minRedemption}
                  onChange={(e) => setMinRedemption(e.target.value)}
                />
                {errors.minRedemptionAmount && (
                  <ErrorText>{errors.minRedemptionAmount}</ErrorText>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Position</LabelInput>
                <Input
                  type="number"
                  id="position"
                  placeholder="Position"
                  name="Position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
                {errors.position && <ErrorText>{errors.position}</ErrorText>}
              </Grid>

              <Grid item xs={12} md={6}>
                <LabelInput>Estimated return</LabelInput>
                <Input
                  type="number"
                  id="estimated_return_percentage"
                  placeholder="Estimated Return"
                  name="estimated_return_percentage"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={estimatedReturn}
                  onChange={(e) => setEstimatedReturn(e.target.value)}
                />
                {errors.estimatedReturn && (
                  <ErrorText>{errors.estimatedReturn}</ErrorText>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Product Comparirision</LabelInput>
                <SubLabelInput>FD</SubLabelInput>
                <Input
                  type="number"
                  id="fd"
                  placeholder="FD"
                  name="fd"
                  value={fd}
                  onChange={(e) => setFd(e.target.value)}
                />
                {errors.fd && <ErrorText>{errors.fd}</ErrorText>}
              </Grid>

              <Grid item xs={12} md={6}>
                <LabelInput color="transparent">
                  {"Product Comparirision"}
                </LabelInput>
                <SubLabelInput>LXME</SubLabelInput>
                <Input
                  type="number"
                  id="lxme"
                  placeholder="LXME"
                  name="lxme"
                  value={lxme}
                  onChange={(e) => setLxme(e.target.value)}
                />
                {errors.lxme && <ErrorText>{errors.lxme}</ErrorText>}
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <SubLabelInput>Savings A/C</SubLabelInput>
                <Input
                  type="number"
                  id="savingsAC"
                  placeholder="Savings A/C"
                  name="savingsAC"
                  value={savingsAC}
                  onChange={(e) => setSavingsAC(e.target.value)}
                />
                {errors.savingsAC && <ErrorText>{errors.savingsAC}</ErrorText>}
              </Grid>
            </Grid>

            {renderPichingFacts()}
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Upload Images, Videos or Documents</LabelInput>
                <Image value={imageFile} onChange={onFileChange} />
                {errors.uploadfile && (
                  <ErrorText>{errors.uploadfile}</ErrorText>
                )}
              </Grid>
            </Grid>
          </StyleModal>
        )}
      </Grid>

      <Grid>
        {showUpdateModal && (
          <StyleModal
            type="number"
            title="Update Portfolio"
            visible={showUpdateModal}
            onOk={(e) => handleUpdateSubmit(e)}
            onCancel={() => handleUpdateModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleUpdateSubmit(e)}
                // loading={loading}
              >
                Update
              </Button>,
            ]}
          >
            <LabelInput>Portfolio Name</LabelInput>
            <Input
              id="Name"
              placeholder="Name"
              name="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {errors.name && <ErrorText>{errors.name}</ErrorText>}

            <LabelInput>Description</LabelInput>
            <Input
              id="Description"
              placeholder="Description"
              name="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            {errors.description && <ErrorText>{errors.description}</ErrorText>}

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>CAGR</LabelInput>
                <Input
                  type="number"
                  id="targetReturn"
                  placeholder="CAGR"
                  name="targetReturn"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={targetReturn}
                  onChange={(e) => setTargetReturn(e.target.value)}
                />
                {errors.targetReturn && (
                  <ErrorText>{errors.targetReturn}</ErrorText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <LabelInput>Time period</LabelInput>
                <Input
                  type="number"
                  id="timePeriod"
                  placeholder="Time period"
                  name="timePeriod"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(e.target.value)}
                />
                {errors.timePeriod && (
                  <ErrorText>{errors.timePeriod}</ErrorText>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Minimum lumpsum amount</LabelInput>
                <Input
                  type="number"
                  id="minLumpsum"
                  placeholder="Time period"
                  name="minLumpsum"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={minLumpsum}
                  onChange={(e) => setMinLumpsum(e.target.value)}
                />
                {errors.minLumpsum && (
                  <ErrorText>{errors.minLumpsum}</ErrorText>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <LabelInput>Maximum lumpsum amount</LabelInput>
                <Input
                  type="number"
                  id="maxLumpsum"
                  placeholder="Maximum lumpsum amount"
                  name="maxLumpsum"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={maxLumpsum}
                  onChange={(e) => setMaxLumpsum(e.target.value)}
                />
                {errors.maxLumpsum && (
                  <ErrorText>{errors.maxLumpsum}</ErrorText>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Minimum SIP amount</LabelInput>
                <Input
                  type="number"
                  id="minSIP"
                  placeholder="Minimum SIP"
                  name="minSIP"
                  maxLength={15}
                  value={minSIP}
                  onChange={(e) => setMinSIP(e.target.value)}
                />
                {errors.minSIP && <ErrorText>{errors.minSIP}</ErrorText>}
              </Grid>
              <Grid item xs={12} md={6}>
                <LabelInput>Maximum SIP amount1</LabelInput>
                <Input
                  type="number"
                  id="maxSIP"
                  placeholder="Maximum SIP amount"
                  name="maxSIP"
                  maxLength={15}
                  value={maxSIP}
                  onChange={(e) => setMaxSIP(e.target.value)}
                />
                {errors.maxSIP && <ErrorText>{errors.maxSIP}</ErrorText>}
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>SIP Dates</LabelInput>
                <Select
                  mode="multiple"
                  placeholder="SIP Dates"
                  style={{ width: "100%" }}
                  defaultValue={sipDates}
                  onChange={(e) => setSipDates(e)}
                >
                  {createOptionList(sipDatesList())}
                </Select>
                {errors.sipDates && <ErrorText>{errors.sipDates}</ErrorText>}
              </Grid>

              <Grid item xs={12} md={6}>
                <LabelInput>System Portfolio ID</LabelInput>
                <Input
                  disabled
                  type="number"
                  id="systemPortfolioID"
                  placeholder="System Portfolio ID"
                  name="systemPortfolioID"
                  value={systemPortfolioID}
                  onChange={(e) => setSystemPortfolioID(e.target.value)}
                />
                {errors.systemPortfolioID && (
                  <ErrorText>{errors.systemPortfolioID}</ErrorText>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Recommended Period</LabelInput>
                <Select
                  placeholder="Recommended Period"
                  style={{ width: "100%" }}
                  value={recommendedPeriod}
                  onChange={(e) => setRecommendedPeriod(e)}
                >
                  {recommendedPeriodList &&
                    createOptionList(recommendedPeriodList.data)}
                </Select>
                {errors.recommendedPeriod && (
                  <ErrorText>{errors.recommendedPeriod}</ErrorText>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <LabelInput>Minimum Redemption Amount</LabelInput>
                <Input
                  type="number"
                  id="min_redemption_amount"
                  placeholder="Minimum Redemption Amount"
                  name="min_redemption_amount"
                  value={minRedemption}
                  onChange={(e) => setMinRedemption(e.target.value)}
                />
                {errors.minRedemptionAmount && (
                  <ErrorText>{errors.minRedemptionAmount}</ErrorText>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Position</LabelInput>
                <Input
                  type="number"
                  id="position"
                  placeholder="Position"
                  name="Position"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                />
                {errors.position && <ErrorText>{errors.position}</ErrorText>}
              </Grid>
              <Grid item xs={12} md={6}>
                <LabelInput>Status</LabelInput>
                <Select
                  placeholder="Status"
                  style={{ width: "100%" }}
                  value={status}
                  onChange={(e) => setStatus(e)}
                >
                  {statusList && createOptionList(statusList.data)}
                </Select>
                {errors.status && <ErrorText>{errors.status}</ErrorText>}
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Product Comparirision</LabelInput>
                <SubLabelInput>FD</SubLabelInput>
                <Input
                  type="number"
                  id="fd"
                  placeholder="FD"
                  name="fd"
                  value={fd}
                  onChange={(e) => setFd(e.target.value)}
                />
                {errors.fd && <ErrorText>{errors.fd}</ErrorText>}
              </Grid>

              <Grid item xs={12} md={6}>
                <LabelInput color="transparent">
                  {"Product Comparirision"}
                </LabelInput>
                <SubLabelInput>LXME</SubLabelInput>
                <Input
                  type="number"
                  id="lxme"
                  placeholder="LXME"
                  name="lxme"
                  value={lxme}
                  onChange={(e) => setLxme(e.target.value)}
                />
                {errors.lxme && <ErrorText>{errors.lxme}</ErrorText>}
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <SubLabelInput>Savings A/C</SubLabelInput>
                <Input
                  type="number"
                  id="savingsAC"
                  placeholder="Savings A/C"
                  name="savingsAC"
                  value={savingsAC}
                  onChange={(e) => setSavingsAC(e.target.value)}
                />
                {errors.savingsAC && <ErrorText>{errors.savingsAC}</ErrorText>}
              </Grid>
              <Grid item xs={12} md={6}>
                <LabelInput>Estimated return</LabelInput>
                <Input
                  type="number"
                  id="estimated_return_percentage"
                  placeholder="Estimated Return"
                  name="estimated_return_percentage"
                  maxLength={15}
                  min={1}
                  max={100}
                  value={estimatedReturn}
                  onChange={(e) => setEstimatedReturn(e.target.value)}
                />
                {errors.estimatedReturn && (
                  <ErrorText>{errors.estimatedReturn}</ErrorText>
                )}
              </Grid>
            </Grid>

            {renderPichingFacts()}
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <LabelInput>Upload Images, Videos or Documents</LabelInput>
                <Image value={imageFile} onChange={onFileChange} />
                {errors.uploadfile && (
                  <ErrorText>{errors.uploadfile}</ErrorText>
                )}
              </Grid>
            </Grid>
          </StyleModal>
        )}
      </Grid>
    </Grid>
  );
};
export default ModelPortfolios;
